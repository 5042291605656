import styled from 'styled-components'
import {vw} from '../utilities/helpers'

export default styled.section`
    width: ${vw(14)};
    margin-left: ${vw(5)};

    @media (max-width: 1023px){
        width: 90%;
        margin: 0 auto;
        padding-top: ${vw(3)};
    }
`