import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import inView from 'in-view'

import { vw, percent, genID, colors} from '../utilities/helpers'

import Blob from './blob'
import Line from './line'
import Text from './text'
import Speakers from './speakers'

const Wrap = styled.section`
    position: relative;
    padding-bottom: ${vw(1.5)};

    opacity: 0;
    transition: opacity 1.5s;

    &.is-visible{
        opacity: 1
    } 

    @media (max-width: 767px){
        padding-bottom: ${vw(4)};
    }
`


const Image = styled.section`
    float: right;
    position: relative;
    width: ${vw(5)};
    top: ${vw(-0.5)};

    @media (max-width: 1023px){
        width: 35%;
    }

    
    @media (max-width: 767px){
        float:none;
        position: static;
        padding: ${vw(2)} 0;
        width: 100%;
    }
`

const Copy = styled.section`
    width: ${vw(7.5)};
    float: left;

    a{
        margin: ${vw(0.75)} 0 ${vw(0.5)};
        text-transform: uppercase;
        text-align: center;
        display: inline-block;
        border: solid 2px #F437BA; 
        color: #fff;
        border-radius: 120px;
        padding: ${vw(0.15)} ${vw(0.5)};
        font-weight: bold;

        :hover{
            border-color: ${colors.yellow};
        }
    }

    

    @media (max-width: 1023px){
        width: 55%;

        a {
            margin: ${vw(1.5)} 0 ${vw(0.75)};
            padding: ${vw(0.25)} ${vw(0.75)};
        }
    }

    @media (max-width: 767px){
        float:none;
        width: auto;

        a {
            margin: ${vw(2.5)} 0 ${vw(1.5)};
            padding: ${vw(0.5)} ${vw(1.5)};
        }
    }
`

const Mask = styled.div`
    position: absolute;
    height: 0;
    width: 0;
    z-index: -100;
`

const Content = styled.section`
    :after{
        content:"";
        display: table;
        clear: both;
    }

    padding-top: ${props => props.size === 'large' ? vw(1.5) : vw(0.75)};
`

const Title = styled.header`
    width:${percent(7 / 13)};
    color: ${colors.green};
    position: relative;
    
    :after{
        content: "";
        height: ${vw(0.05)};
        width: 15%;
        background-color: ${colors.green};
        right: -50%;
        top: 50%;
        position: absolute;
        display: ${props => props.size === 'large' ? 'block' : 'none'};
    }

    h2 {
        color: ${colors.yellow};
    }

    > span:first-child {
        padding-bottom: ${vw(0.2)};
        text-transform: uppercase;
        font-weight: 700;
        color: ${colors.yellow}
    }

    @media (max-width: 767px){
        width: auto;
    }
`

const Grid = styled.section`
    padding-top: ${vw(1)};

    @media (max-width: 767px){
        padding-top: ${vw(2)};
    }
`
const id = genID()



export default(props) => {
    const obj = useRef(null)
    const speakers = useRef(null)
    const speakersBtn = useRef(null)

    const [visible, setVisible] = useState('')

    useEffect(() => {

        if(props.speakers){
            
            if (speakersBtn){
                let html = speakers.current.innerHTML
                const body = document.documentElement
                const lightbox = document.querySelector('.lightbox')
                const lightboxContent = document.querySelector('.lightbox-media')

                speakersBtn.current.addEventListener('click', function(e){
                    e.preventDefault()

                    body.classList.add('u-oh')

                    lightboxContent.innerHTML = html
                    lightbox.classList.add('is-visible')
                })
            }
        }
        
        const onScroll = () => {
            if (inView.is(obj.current)) {
                setVisible('is-visible')
            }
        }

        onScroll()
        document.addEventListener("scroll", onScroll)

        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    }, [])

    return (
        <Wrap size={props.size} ref={obj} className={visible}>
            
            <Title size={props.size}>
                {props.subtitle && 
                    <Text tiny>{props.subtitle}</Text>
                }

                {props.size === 'large' && 
                    <h1><Text xlarge>{props.title}</Text></h1>
                }

                {props.size === 'medium' &&
                    <h2><Text large>{props.title}</Text></h2>
                }
            </Title>

            {props.grid && 
                <Grid>
                    {props.children}
                </Grid>
            }  

            {(!props.grid && props.length > 10) && 
                <Content size={props.size}>
                    {(!props.preface && props.fg) && 
                        <Image float={props.float}>
                            <Blob id={genID()} fg={props.fg} bg={props.bg} size={props.size} video={props.video} icon={props.icon}/>
                        </Image>
                    }
                    
                    <Copy float={props.float}>
                        {props.children}

                        {(props.to && props.label && !props.speakers) && 
                            <a href={props.to}><Text small>{props.label}</Text></a>
                        }

                        {props.speakers && 
                            <a href='#' ref={speakersBtn}><Text small>View Speakers</Text></a>
                        }
                        <Line red/>
                    </Copy>
                </Content>
            }

            {props.speakers && 
                <>
                <div className="u-dn" ref={speakers}>
                    <Speakers speakers={props.speakers} />
                </div>
                <Mask>
                    <svg viewBox="0 0 0 0">
                        <defs>
                            <mask id="speakerMask" fill="#fff">
                                <path d="M98.6,5.9C64.7-6.6,16.9,0.7,3.8,27.8c-16.3,33.8,21.4,97.7,70.5,99.4c37.2,1.2,77.5-33.4,74.5-68.6 C145.8,24,102.8,7.5,98.6,5.9z" />
                            </mask>
                        </defs>
                    </svg>
                </Mask>
                </>
            }
        </Wrap>
    )
}
