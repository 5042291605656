import React from "react"
import { graphql } from "gatsby"

import { vw, paths, patterns, colors, genID } from "../utilities/helpers"
import SEO from "../utilities/seo"

import Block from "../components/block"
import Body from "../components/wrap"
import Bit from "../components/bit"
import Glow from "../components/glow"
import Preface from "../components/preface"
import Submenu from "../components/submenu"
import Slice from "../components/slice"
import Meta from "../components/meta"
import Grid from "../components/grid"
import Next from "../components/next"
import Parallax from "../components/parallax"
import Footer from "../components/footer"
import Text from "../components/text"

const id = genID()

function renderPreface(content, index) {
  const item = content.node.data
  const video = item.video.document["data"]
  const doc = item.document.document["data"]
  return (
    <Block key={index} className="page-preface">
      <svg
        className="page-preface__image"
        viewBox="0 0 1320 720"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          <linearGradient
            id={id + "f"}
            gradientUnits="objectBoundingBox"
            gradientTransform="rotate(90)"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#000" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={id + "g"}
            gradientUnits="objectBoundingBox"
            gradientTransform="rotate(90)"
          >
            <stop offset="0" stopColor="#4744E5" />
            <stop offset="1" stopColor="#000" stopOpacity="0" />
          </linearGradient>
          <mask id={id + "m"}>
            <path fill={"url(#" + id + "f)"} x="100px" d={paths.a} />
          </mask>
        </defs>
        <path fill={"url(#" + id + "g)"} d={paths.e} />
        <g mask={"url(#" + id + "m)"}>
          <image
            xlinkHref={item.image.url}
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
          ></image>
        </g>
      </svg>

      <Preface
        title={item.heading.text}
        summary={item.preface.text}
        video={[
          {
            url: video.link.embed_url,
            thumb: video.link.thumbnail_url,
            subtitle: video.title.text,
          },
        ]}
        doc={[
          {
            href: doc.link.url,
            thumb: doc.cover.url,
            subtitle: doc.title.text,
          },
        ]}
      />

      <Bit ring top={vw(3)} left={vw(10)} />
      <Bit cross top={vw(6)} right={vw(2)} />
      <Bit arrow top={vw(10)} right={vw(12)} />
      <Bit arrow top={vw(10.5)} right={vw(12)} />

      <Glow cool top={vw(-4)} left={vw(-4)} delay="0" />
      <Glow royal bottom={vw(-5)} right={vw(-4)} delay="1.5" />
    </Block>
  )
}

function sanitizeString(str) {
  str = str.replace(/[^a-z0-9áéíóúñü,_-]/gim, "")
  return str.trim()
}

function renderSources(item, index) {
  return (
    <li key={index}>
      <Text small>
        {index + 1} * {item}
      </Text>
    </li>
  )
}

function renderSlices(content, index) {
  var type = content.slice_type
  var level = ""
  var size = ""
  var title = ""
  var subtitle = ""
  var venue = ""
  var image = ""
  var time = ""
  var html = ""
  var id = ""
  var heading = ""
  var link = ""
  var linkLabel = ""
  var cellCount = 0

  if (type === "image_list" || type === "statistics") {
    var cells = []
    var sources = []

    cellCount = content.items.length

    cellCount < 4 ? (cellCount = 3) : (cellCount = 4)

    if (
      (type === "statistics" || type === "image_list") &&
      content.primary !== null
    ) {
      heading = content.primary.title.text
    }

    content.items.forEach(function (ele, pos) {
      var cellTitle = ""
      var cellSubtitle = ""
      var cellImage = ""
      var cellIcon = ""
      var cellType = ""
      var cellUnit = ""
      var cellNumber = 0

      if (type === "image_list") {
        cellTitle = ele.title.text
        cellSubtitle = ele.subtitle.text
        cellImage = ele.list_image.url
        cellIcon = ""
      }

      if (type === "statistics") {
        cellSubtitle = ele.caption.text
        cellType = "text"
        cellIcon = ele.icon.url
        cellImage = ""
        cellUnit = ele.unit.text
        cellNumber = ele.number

        sources[pos] = ele.source.text
      }

      cells[pos] = {
        key: pos,
        type: cellType,
        subtitle: cellSubtitle,
        fg: cellImage,
        icon: cellIcon,
        fill: colors.green,
        bg: patterns.green,
        unit: cellUnit,
        number: cellNumber,
        title: cellTitle,
      }
    })

    return (
      <Slice grid key={index} size="medium" title={heading}>
        <Grid col={cellCount} cells={cells} />

        {sources.length > 0 && (
          <ul className="sources">{sources.map(renderSources)}</ul>
        )}
      </Slice>
    )
  }

  if (type === "section") {
    var node = content.primary

    level = node.level
    title = node.section_title.text
    subtitle = node.subtitle.text
    image = node.section_image.url
    html = node.section_content.html
    venue = node.venue.text
    time = node.timestamp

    if (node.link !== null) {
      link = node.link.url
    }

    if (node.link_label !== null) {
      linkLabel = node.link_label.text
    }

    level === "1" ? (size = "large") : (size = "medium")

    if (level === "1") {
      id = "m" + sanitizeString(node.menu_label.text).replace(/\s/g, "")
    }

    return (
      <section id={id} key={index}>
        <Slice
          size={size}
          length={html.length}
          bg={patterns.green}
          fg={image}
          title={title}
          subtitle={subtitle}
          to={link}
          label={linkLabel}
        >
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {venue && time && (
            <Meta
              data={[
                {
                  key: 1,
                  type: "location",
                  label: venue,
                },
                {
                  key: 2,
                  type: "time",
                  label: time,
                },
              ]}
            />
          )}
        </Slice>
      </section>
    )
  }
}

export default ({ data }) => {
  const menu = [
    {
      key: 0,
      label: "Archives",
      anchor: "/archive",
    },
  ]
  const menuItems = data.allPrismicPage.edges[0].node.data.body1

  menuItems.forEach(function (ele, index) {
    if (ele.primary) {
      const item = ele.primary

      if (item.level === "1") {
        var label = item.menu_label.text
        var id = sanitizeString(label).replace(/\s/g, "")

        menu[index] = {
          key: index,
          label: label,
          anchor: "#m" + id,
        }
      }
    }
  })

  menu[menuItems.length] = {
    key: menuItems.length,
    label: "Past IGDs",
    anchor: "/archive",
  }

  return (
    <>
      <main>
        <SEO
          description={data.allPrismicPage.edges[0].node.data.preface.text}
          title={data.allPrismicPage.edges[0].node.data.label.text}
          image={data.allPrismicPage.edges[0].node.data.image.url}
        />

        <Submenu items={menu} className="submenu" />

        {data.allPrismicPage.edges.map(renderPreface)}

        <Block>
          <Glow cool top="-5%" left={vw(-4)} delay="0" />
          <Bit ring top="5%" left="15%" />

          <Glow warm top="15%" right={vw(-6)} delay="0.75" />
          <Bit cross top="15%" right="15%" />

          <Glow royal top="50%" left={vw(-6)} delay="0" />
          <Bit cross top="50%" left="15%" />

          <Glow royal bottom="-5%" right={vw(-4)} delay="0.75" />
          <Bit ring top="100%" right="15%" />

          <Body className="page-content">
            {data.allPrismicPage.edges[0].node.data.body1.map(renderSlices)}
          </Body>
        </Block>

        <Next to="/program" title="Program" />

        <Parallax a />
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query aboutQuery {
    allPrismicPage(filter: { uid: { eq: "about" } }) {
      edges {
        node {
          data {
            body1 {
              ... on PrismicPageDataBody1ImageList {
                primary {
                  title {
                    text
                  }
                }
                items {
                  list_image {
                    url
                  }
                  subtitle {
                    text
                  }
                  title {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageDataBody1Section {
                slice_type
                primary {
                  level
                  section_content {
                    html
                  }
                  section_image {
                    url
                  }
                  link_label {
                    text
                  }
                  link {
                    url
                  }
                  section_title {
                    text
                  }
                  subtitle {
                    text
                  }
                  timestamp(formatString: "dddd DD MMMM YYYY")
                  venue {
                    text
                  }
                  menu_label {
                    text
                  }
                }
              }
              ... on PrismicPageDataBody1Statistics {
                id
                primary {
                  title {
                    text
                  }
                }
                items {
                  caption {
                    text
                  }
                  source {
                    text
                  }
                  icon {
                    url
                  }
                  unit {
                    text
                  }
                  number
                }
                slice_type
              }
            }
            preface {
              text
            }
            timestamp(formatString: "dddd DD MMMM YYYY")
            venue {
              text
            }
            video {
              document {
                ... on PrismicVideo {
                  data {
                    link {
                      embed_url
                      thumbnail_url
                    }
                    title {
                      text
                    }
                  }
                }
              }
            }
            image {
              url
            }
            heading {
              text
            }
            label {
              text
            }
            document {
              document {
                ... on PrismicDocument {
                  data {
                    cover {
                      url
                    }
                    link {
                      url
                    }
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`
