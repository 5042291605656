import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import {vw, colors, icons} from '../utilities/helpers'

import Text from './text'

const Nav = styled.nav`
    position: fixed; 
    width: ${vw(3)};
    top: ${vw(4)};
    left: ${vw(1.5)};
    z-index: 3;
    
    opacity: 0;
    transform:translate3d(0, 50%, 0);
    transition: transform 0.75s, opacity 0.75s;

    &.is-visible{
        transform: translate3d(0,0,0);
        opacity: 1; 
    }

    a {
        display: block;
        padding-bottom: ${vw(0.25)};
        font-weight: bold;
        position: relative;
        color: ${colors.green};

        :after{
            content: "";
            position: absolute;
            top: 20%;
            background-image: url('${icons.arrow}');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            left: ${vw(-0.5)};
            width: ${vw(0.155)};
            height: ${vw(0.175)};
        }

        &.is-active, :hover{
            color: #fff;
        }

        @media (max-width: 1023px){
            display:none;
        }
    }
`

function createItem(item){
    const label  = <Text med>{item.label}</Text>
    if (item.anchor !== '/archive'){
        return (
            <a key={item.key} href={item.anchor}>{label}</a>
        )
    } else {
        return (
            <Link key={item.key} to={item.anchor}>{label}</Link>
        )
    }
}

export default (props) => {
    return (
        <Nav className={props.className}>
            {props.items.map(createItem)}
        </Nav>
    )
}