import React from 'react'
import styled from 'styled-components'

import { vw, colors, patterns, icons} from '../utilities/helpers'

import Line from './line'
import Meta from './meta'
import Grid from './grid'

import Text from './text'

const Preface = styled.section`
    position: absolute;
    width: ${vw(18)};
    height: ${vw(8)};
    top: ${vw(3.5)};
    left: ${vw(2)};
    z-index: 10;

    h1 {
        width: ${vw(8)}
    }

    > section {
       margin-top: ${vw(1)}; 
       position: relative;
        
       p{
           a{
               margin: 0;
               color: ${colors.green};
           }
       }
    }

    @media (max-width: 1023px){
        width: 90%;
        left: 5%;

        h1{
            width: 50%;
        }
    }

    @media (max-width: 767px){
        position: static;
        height: auto;
        margin: 0 auto;
        padding-top: 24px;

        h1{
            width: auto;
        }

        > section {
            margin-top: 0;
            padding-top: 48px;
        }
    }
`
const Summary = styled.section`
    position: absolute;
    width: ${vw(8)};
    left: ${vw(1)};

    @media (max-width: 1023px){
        width: 60%;
    }

    @media (max-width: 767px){
        position: relative;
        width: auto;
        left: 0;
    }
`

const Media = styled.section`
    position: absolute;
    width: ${vw(7)};
    right: 0;
    top: ${vw(-1)};

    :after{
        content: "";
        display: table;
        clear: both;
    }

    > a {
        float: left;
        
        :last-child{
            margin-left: ${vw(1)};
        }
    }

    @media (max-width: 767px){
        position: static;
        width: auto;
        padding-top: ${vw(4)};
    }
`

export default (props) => {
    
    let cells = []

    if(props.video){
        cells[0] = {
            'subtitle': props.video[0].subtitle,
            'video': props.video[0].url,
            'fg': props.video[0].thumb,
            'bg': patterns.green,
            'icon': icons.play,
            'key': 0
        }
    }

    if(props.doc){
        cells[1] = {
            'subtitle': props.doc[0].subtitle,
            'video': props.doc[0].link,
            'fg': props.doc[0].thumb,
            'bg': patterns.green,
            'icon': icons.doc,
            'href': props.doc[0].href,
            'key':1
        }
    }

    return(
        <Preface>
            <h1><Text xlarge>{props.title}</Text></h1>
            <section>
                <Summary>
                    {props.summary &&
                        <p>{props.summary} <Line /></p>
                    }

                    {props.meta &&
                        <Meta data={props.meta} />
                    }
                </Summary>
                {(props.video || props.document) && 
                    <Media>
                        <Grid col={2} cells={cells}/>
                    </Media>
                }
                {props.children}
            </section>
        </Preface>
    )
}