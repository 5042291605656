import React from 'react'
import styled from 'styled-components'

import Text from './text'
import {vw, percent } from '../utilities/helpers'


const Speaker = styled.section`
    width: ${percent(1/4)};
    position: relative;
    
    > div {
        margin: ${vw(0.5)};
        position: relative;
        text-align: center;
    }

    svg {
        position: relative;
    }

    h2{
        padding-top: ${vw(0.25)};
    }
    
    @media (max-width: 767px){
        width: 100%;
        margin-bottom: ${vw(3)};
    }
`

const Grid = styled.section`
    display: flex;
    position: absolute;
    overflow: scroll;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    @media (max-width: 767px){
        height: 80vh;
    }
`

const Subtitle = styled.div`
    padding-top: ${vw(0.125)};
`

function createSpeaker(item, index) {
    return (
        <Speaker key={index}>
            <div>
                <svg viewBox="0 0 153.5 131.9">
                    <g mask="url(#speakerMask)">
                        <image xlinkHref={item.fg} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice"></image>
                    </g>
                </svg>
                <h2><Text med>{item.title}</Text></h2>
                <Subtitle>
                    <Text small>{item.subtitle}</Text>
                    <Text small>{item.organization}</Text>
                </Subtitle>
            </div>
        </Speaker>
    )
}

export default(props) => {
    return(
        <Grid>
            {props.speakers.map(createSpeaker)}
        </Grid>
    )
}